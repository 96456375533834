import React from 'react';

export default function About() {
    return (
        <div className='flex flex-col md:flex-row max-w-2xl m-auto items-center justify-center between  absolute inset-0  min-h-screen'>
            <div className='text-right p-4 '>
                <h1 className='text-2xl text-white md:w-36'>
                    <em></em>Infinite Leaves
                </h1>
            </div>
            <div className='p-4 mr-auto  text-lg text-left space-y-4 text-stone-300 border-stone-400 md:border-t-0 border-t-2 md:border-l-2'>
                <p>
                    Infinite Leaves is a digital art exhibit that marries the
                    poetry of Walt Whitman's <em>Leaves of Grass</em> with
                    AI-generated images, created using Stable Diffusion.
                </p>
                <p>
                    Over the course of a 20 hour loop, Infinite Leaves displays,
                    in order, every verse of <em>Leaves of Grass</em> paired
                    with one of over 25,000 images generated based on a GPT-4
                    interpretation.
                </p>
                <p>
                    Infinite Leaves continues whether you watch or not. Tune in
                    at different times of the day to discover new, and sometimes
                    unsettling, creations.
                </p>

                <p>
                    <a
                        className='underline'
                        href='https://whitmanarchive.org/published/LG/1867/index.html'
                    >
                        A full table of contents can be found here.
                    </a>
                </p>
                <p>
                    <a
                        className='underline text-sm'
                        href='https://github.com/rypmaloney/infinite-leaves'
                    >
                        Visit the Github Repository.
                    </a>
                </p>
                <p className='text-sm text-stone-400'>
                    AI reflects societal biases, including those present in the
                    data it is trained on. Not all of the images generated by
                    this app have been manually reviewed.
                </p>
            </div>
        </div>
    );
}
